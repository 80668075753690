
.artis-slide {
    position: relative;
    margin: 0 auto;
}
.swiper-button-prev,
.swiper-button-next {
    margin: initial;
    border-radius: 50%;
    background-color: white;
    &::before {
        display: none;
    }

    &:hover {
        border-color: var(--v-primary-base);
        .v-icon {
            color: var(--v-primary-base);
        }
    }
}
.swiper-button-prev {
    left: -40px !important;
    transform: translateX(-100%);
}
.swiper-button-next {
    right: -40px !important;
    transform: translateX(100%);
}
.swiper-pagination {
    justify-content: center;
    position: static;
    margin: 0;
    ::v-deep {
        .swiper-pagination-bullet {
            width: 10px !important;
            height: 10px !important;
            background-color: var(--v-grey-lighten3) !important;
            border: none;
            &-active {
                background-color: var(--v-primary-base) !important;
            }
        }
    }
}
