
::v-deep {
    #contents {
        padding-top: 0;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}

.list {
    margin: 40px 0 0;
    > li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.tip-list {
    > li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .list {
        margin: 80px 0 0;
        > li {
            padding-left: 32px;
            margin-bottom: 16px;
            &::before {
                width: 6px;
                height: 6px;
                left: 6px;
                top: 9px;
            }
        }
    }
    .tip-list {
        > li {
            margin-bottom: 16px;
        }
    }
}
