
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.swiper-pagination--center {
    left: 50%;
    bottom: -12px;
    transform: translate(-50%, 100%);
    justify-content: center;
    :deep(.swiper-pagination-bullet) {
        border: none !important;
        background-color: #e2e8f0 !important;
        &-active {
            background-color: #007aff !important;
        }
    }
}
