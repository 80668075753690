
.v-tabs {
    .v-tab {
        border-bottom: 1px solid var(--v-grey-lighten3);
        text-transform: initial;
        padding: 12px;
        height: auto;
        width: 50%;
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 4px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            background-color: var(--v-primary-base);
            transition: all 0.2s;
        }

        &--active {
            &::after {
                width: 100%;
            }
        }
    }
    ::v-deep {
        .v-tabs-bar {
            height: auto;
        }
        .v-slide-group__wrapper {
            width: 100%;
        }
        .v-slide-group__content {
            width: 100%;
            flex-wrap: wrap;
            flex: initial !important;
        }
        .v-slide-group__prev .v-slide-group__next {
            display: none;
        }
    }
}

@media (min-width: 768px) {
    .v-tabs {
        border-left: 1px solid var(--v-grey-lighten3);
        .v-tab {
            border-bottom: none;
            width: auto;
            padding: 24px;
            justify-content: start;
            > span {
                text-align: start;
            }

            &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 0;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--v-primary-base);
                transition: all 0.2s;
            }

            &--active {
                &::after {
                    height: 100%;
                }
            }
        }
    }
}
