
::v-deep {
    #contents {
        padding-top: 0;
    }
}
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}

.list {
    margin: 40px 0 0;
    > li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.tip-list {
    > li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .list {
        margin: 80px 0 0;
        > li {
            padding-left: 32px;
            margin-bottom: 16px;
            &::before {
                width: 6px;
                height: 6px;
                left: 6px;
                top: 9px;
            }
        }
    }
    .tip-list {
        > li {
            margin-bottom: 16px;
        }
    }
}

.image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
}
@media (min-width: 768px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}

.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }

    &--secondary {
        left: initial;
        right: 0;
        &::before {
            left: initial;
            right: -100vw;
        }
    }
}

@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
}

.v-expansion-panels {
    .v-expansion-panel {
        background-color: transparent;
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}

@media (min-width: 768px) {
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
