
.container {
    position: relative;
}
.section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--v-grey-lighten5);
    z-index: -1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }

    &--secondary {
        left: initial;
        right: 0;
        &::before {
            left: initial;
            right: -100vw;
        }
    }
}

@media (min-width: 768px) {
    .section-bg {
        width: calc(100% / 12 * 8);
        height: 100%;
    }
    .container {
        padding-top: var(--grid-gutter-lg);
        padding-bottom: var(--grid-gutter-lg);
    }
    .full-image-wrap {
        height: calc(678px - var(--grid-gutter-lg) * 2);
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: calc(-1 * var(--grid-gutter-lg));
            right: 0;
            aspect-ratio: calc(1136 / 678);
            background-image: url(/images/ideas/bathroom/features/metalrim.webp);
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100% + var(--grid-gutter-lg) * 2);
        }
    }
}
