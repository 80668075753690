
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-expansion-panels {
    .v-expansion-panel {
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}
.function-content-list {
    list-style: disc;
    padding-left: 20px;
    > li {
        line-height: 1.4;
    }
}
@media (min-width: 768px) {
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
