
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.glance-list {
    > li {
        padding-left: 20px;
        margin-bottom: 12px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 4px;
            top: 8px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
    }
}
@media (min-width: 768px) {
    .glance-list {
        > li {
            margin-bottom: 16px;
            &::before {
                top: 8px;
            }
        }
    }
}
