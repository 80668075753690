
.v-card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    .v-image {
        transform: scale(1.1);
        transition: all 0.4s;
    }
    &__name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 16px;
        z-index: 1;
        transition: all 0.4s;
    }
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--v-primary-base);
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        opacity: 0.8;
        transition: all 0.4s;
        border-radius: var(--rounded-xs);
    }
}
@media (min-width: 768px) {
    .v-card {
        &:hover {
            .v-card {
                &__name {
                    bottom: 50%;
                    transform: translate(-50%, 50%);
                }
            }
            .v-image {
                transform: scale(1);
            }
            &::after {
                transform: translateY(0);
            }
        }
    }
}
