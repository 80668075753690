
.cleaning-image {
    &--1 {
        max-width: 674px;
    }
    &--2 {
        max-width: 300px;
        margin-left: auto;
        margin-right: calc(-1 * var(--container-gutter));
        margin-top: -100px;
        position: relative;
        z-index: 1;
    }
    &--3 {
        max-width: 252px;
        margin-top: -180px;
        margin-left: calc(-1 * var(--container-gutter));
    }
}
@media (min-width: 768px) {
    .cleaning-image {
        &--2 {
            max-width: 368px;
            margin-right: 270px;
            margin-top: -130px;
        }
        &--3 {
            max-width: 368px;
            margin: initial;
            position: absolute;
            top: 100px;
            right: 0;
        }
    }
}
