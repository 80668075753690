
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.color-image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
    .color-text {
        font-size: 12px;
        line-height: 1.8;
        margin-top: 40px;
    }
}
.cautions-list {
    > li {
        padding-left: 20px;
        margin-bottom: 12px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 4px;
            top: 8px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--v-grey-base);
        }
    }
}
.v-expansion-panels {
    .v-expansion-panel {
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}
@media (min-width: 768px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
    .cautions-list {
        > li {
            margin-bottom: 16px;
            &::before {
                top: 10px;
            }
        }
    }
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
        .color-text {
            position: absolute;
            bottom: 100px;
            margin: initial;
        }
    }
}
